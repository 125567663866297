<template>
    <div class="special-task-center-index">
        <div class="special-tab" :class="[{ 't-active': selectMenu == item.value }, item.value]" v-for="item in tabMenu"
            :key="item.value" @click="menuChange(item)">{{ item.name }}</div>
        <div class="special-task-box">
            <specialList :mode="'new'" @goPlay="goPlay" :show="selectMenu == 'new'"></specialList>
            <specialList :mode="'history'" @goPlay="goPlay" :show="selectMenu == 'history'"></specialList>
        </div>
    </div>
</template>

<script>
import specialList from './specialList.vue'
export default {
    name: '',
    props: {
    },
    data() {
        return {
            selectMenu: 'new',
            tabMenu: [
                {
                    name: '新任务',
                    value: 'new'
                },
                {
                    name: '历史任务',
                    value: 'history'
                }
            ],
        };
    },
    methods: {
        menuChange(val) {
            const { value } = val || {}
            this.selectMenu = value || 'new'
        },
        // 点击任务进入
        goPlay(v) {
            this.$emit('goPlay', v)
        },
    },
    created() {
    },
    components: {
        specialList
    },
    computed: {
    },
    watch: {
    },
    mounted() {
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() {
    }, //生命周期 - 更新之前
    updated() {
    }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
.special-task-center-index {
    position: relative;
    @tab-width: 17px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;

    >.special-tab {
        position: absolute;
        top: 0;
        left: 0;
        width: @tab-width;
        height: 50%;
        cursor: pointer;
        background-color: #FBDCA1;
        font-size: 8px;
        font-weight: bold;
        color: #FF9B1C;
        writing-mode: vertical-rl;
        text-align: center;

        &.new {
            top: 0;
        }

        &.history {
            top: 50%;
        }

        &.t-active {
            color: #ffffff;
            background-color: #FFBC6F;
        }
    }

    >.special-task-box {
        position: absolute;
        left: @tab-width;
        top: 0;
        width: calc(100% - @tab-width);
        height: 100%;
    }
}
</style>