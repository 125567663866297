<!-- -->
<template>
    <div class='mind-taskcenter-index flex-center' v-if="show">
        <div class="mind-taskcenter-container">
            <div class="mark"></div>
            <div class="center-bg">
                <div class="off-btn" @click="close()"></div>
                <div class="sign-btn flex-center scale-button" @click="handleSignIn(signIn)">
                    <span v-if="signIn">{{ "已签到" }}</span>
                    <span v-else>{{ "签到" }}</span>
                </div>
                <div class="sign-content">
                    <span class="sign-tip">{{ "连签三天额外+10思维币，连签七天额外+20思维币"
                        }}</span>
                    <div class="sign-calendar">
                        <div class="calendar-item flex-center" :class="v.signValue" v-for="(v, i) in week" :key="i">
                            <div class="sign-icon"></div>
                            <span class="calendar-text">{{ v.day }}</span>
                        </div>
                    </div>
                </div>
                <!-- 任务内容 -->
                <div class="center-content">
                    <div class="tab-menu" :class="[{ 't-active': selectMenu == item.value }, item.value]"
                        v-for="item in tabMenu" :key="item.value" @click="handleTask(item)">
                        <span class="tab-text">{{ item.name }}</span>
                    </div>
                    <div class="center-body">
                        <div class="task-content" v-show="selectMenu == 'normal'">
                            <dailyTask :data="taskCon" @goPlay="goPlay"></dailyTask>
                        </div>
                        <div class="task-content" v-show="selectMenu == 'special'">
                            <specialTask @goPlay="goPlay"></specialTask>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import com from '@/components/taskCenter.vue'
import taskApiClass from '@/API/page/taskcenter'
import apiClass from '@/API/page/taskcenter'
import userApiClass from '@/API/page/user'
import dailyTask from './center/dailyList.vue'
import specialTask from './center/specialTask.vue'
export default {
    name: '',
    // extends: com,
    props: {},
    components: {
        dailyTask,
        specialTask
    },
    data() {
        return {
            api: new apiClass(),
            taskApi: new taskApiClass(),
            userApi: new userApiClass(),
            appData: new this.appDataClass({ key: 'taskCenter' }),
            show: false,
            selectMenu: "normal",
            largeList: [],
            tabMenu: [
                {
                    name: "日常任务",
                    value: "normal"
                },
                {
                    name: "专属任务",
                    value: "special"
                },
            ],
            taskCon: [
                { img: 'win', taskName: "", taskDetail: "", taskStatus: "0", fun: "" },
                { img: 'work', taskName: "", taskDetail: "", taskStatus: "0", fun: "" },
                { img: 'video', taskName: "", taskDetail: "", taskStatus: "0", fun: "" },
                // { img: 'c11', taskName: "", taskDetail: "", taskStatus: "0", fun: "" },
            ],
            routerNameObj: {
                'GAME_WIN': 'game-list',
                'COURSE_EXERCISE': 'task',
                'PUZZLE_EXERCISE': 'subject',
                'VIDEO_LESSON': 'cabin',
                'PALACE': 'palace',
                '3': 'task-subject-section', // 练功房
                '4': 'mind-palace-island', // 知识海岛
            },
            signIn: 0,
            week: [
                { day: '周一', signValue: 'c2_2' },
                { day: '周二', signValue: 'c2_2' },
                { day: '周三', signValue: 'c2_2' },
                { day: '周四', signValue: 'c2_2' },
                { day: '周五', signValue: 'c2_2' },
                { day: '周六', signValue: 'c2_2' },
                { day: '周日', signValue: 'c2_2' },
            ],
        };
    },
    methods: {
        open() {
            this.show = true
            this.taskList()
        },
        close() {
            this.show = false
            this.$emit('close')
        },
        handleTask(val) {
            const { value } = val || {}
            this.selectMenu = value || 'normal'
        },
        // 签到
        async handleSignIn(val) {
            if (val) return false
            let { usercode: user_code } = this
            let opt = {
                user_code,
                // schoolId,
                task_id: '1',//签到
            }
            let res = await this.api.taskSign(opt)
            if (res) {
                this.taskList()
                await this.userApi.userAccount({ noLoad: true, noError: true })
            }
        },
        goPlay(v) {
            let { fun, disabled } = v
            if (disabled) return
            if (!fun) return this.goPlaySpecial(v)
            this.close()
            let query = {}
            let name = this.$route.name
            query[this.queryName] = name
            this.appData.setStoreItem({ 'taskCenterOpenRoute': this.$route.fullPath })
            this._routeHand.goto({
                name: fun,
                type: 'push',
            })
        },
        // 获得日常任务列表
        async taskList() {
            let { usercode: user_code } = this
            let opt = { user_code }
            let res = await this.api.taskList(opt)
            if (res) {
                let { signInList: list, taskList, dailySignInFlag } = res
                this.signIn = dailySignInFlag
                let { week, taskCon } = this
                for (const i in list) {
                    if (Object.hasOwnProperty.call(list, i)) {
                        if (list[i] == 1) week[i].signValue = "c2_0"
                        if (list[i] == 0) week[i].signValue = "c2_1"
                        if (list[i] == -1) week[i].signValue = "c2_2"
                    }
                }
                for (const i in taskList) {
                    if (!taskCon[i]) continue
                    if (Object.hasOwnProperty.call(taskList, i)) {
                        let { townTask, taskStatus } = taskList[i] || {}
                        taskCon[i].taskName = townTask.taskName || ''
                        taskCon[i].taskDetail = townTask.taskDetail || ''
                        taskCon[i].taskStatus = taskStatus
                        taskCon[i].fun = this.getRouteName(townTask.taskCode)
                    }
                }
            }
        },
        async goPlaySpecial(v){
            let { disabled, homeworkType} = v || {}
            if (disabled) return
            let fun = this.getRouteName(homeworkType)
            if (!fun) return
            this.close()
            let {query, params} = await this.getParams(v)
            this.appData.setStoreItem({ 'taskCenterOpenRoute': this.$route.fullPath })
            this._routeHand.goto({
                query,
                params,
                name: fun,
                type: 'push',
            })
        },
        getRouteName(taskCode) {
            taskCode = taskCode || ''
            let { routerNameObj } = this
            return routerNameObj[taskCode] || ''
        },
        async getParams(val){
            let { homeworkType, homeworkId, difficulty } = val || {}
            let params = {}, query = {}
            if(homeworkType == 3) {
                let {sectionName, courseId} = await this.getSubjectParent(homeworkId) || {}
                params = {
                    courseId, // 课程id
                    sectionName, // 章节名
                    id: homeworkId, // 章节id
                    difficulty, // 难度
                } 
            }
            if(homeworkType == 4) {
                let {gradeId, bigIslandId, smallIslandId, palaceType} = await this.getPalaceParams(homeworkId) || {}
                query = {
                    palaceType, // 思维岛1/实战岛2
                    headId: gradeId, // 年级目录Id
                }
                params = {
                    currentLargePalaceId: bigIslandId, // 大岛Id
                    extendId: smallIslandId, // 小岛Id
                }
            }
            params = {
                routeData: this._common.encodeData(params)
            }
            return {params, query}
        },
        async getSubjectParent(section_id){
            if(!section_id) return
            let res = await this.taskApi.getSubjectParent({section_id}) 
            return res
        },
        async getPalaceParams(palace_id){
            if(!palace_id) return
            let res = await this.taskApi.getPalaceParent({palace_id}) 
            return res
        },
    },
    created() {
    },
    computed: {
        usercode(){
            let {
                app: {usercode} = {}
            } = this.$store.state
            return usercode
        },
    },
    watch: {},
    mounted() {
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
.mind-taskcenter-index {
    width: 100%;
    padding-right: @app-padding-side;
    height: 100%;

    >.mind-taskcenter-container {
        z-index: 301;
        background: rgba(0, 0, 0, 0.65);
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;

        .mark {
            width: 100vw;
            height: 100vh;
        }

        >.center-bg {
            .background-img-max(url('@{assetsUrlV2_0}/taskCenter/bg.png'));
            position: absolute;
            transform: translateY(-10px);
            top: 0px;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 462px;
            height: 322px;

            >.off-btn {
                .background-img-max(url('@{assetsUrlV2_0}/task_center/d5.png'));
                position: absolute;
                top: 52px;
                right: -5px;
                width: 17px;
                height: 17px;
                cursor: pointer;
                z-index: 1;
            }

            >.sign-btn {
                .background-img-max(url('@{assetsUrlV2_0}/taskCenter/sign_btn.png'));
                position: absolute;
                top: 80px;
                right: 21px;
                width: 68px;
                height: 25px;
                z-index: 1;

                >span {
                    font-size: 13px;
                    color: #fff;
                    font-weight: bold;
                    line-height: 1em;
                    margin-bottom: 2px;
                }
            }

            >.sign-content {
                width: 100%;
                height: 40px;
                position: absolute;
                left: 0;
                top: 105px;
                padding: 0 22px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: start;
                gap: 7px;

                >.sign-tip {
                    flex-shrink: 0;
                    font-size: 9px;
                    color: #FFA100;
                    font-weight: bold;
                    line-height: 1em;
                }

                >.sign-calendar {
                    width: 100%;
                    height: 0;
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 6px;

                    >.calendar-item {
                        width: 45px;
                        height: 23px;
                        position: relative;

                        &.c2_0 {
                            .background-img-max(url('@{assetsUrlV2_0}/taskCenter/sign.png'));

                            >.sign-icon {
                                .background-img-max(url('@{assetsUrlV2_0}/taskCenter/sign_circle.png'));
                                width: 15px;
                                height: 15px;
                                position: absolute;
                                top: -4px;
                                right: -4px;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    left: 3px;
                                    transform: translateY(-50%);
                                    width: 8px;
                                    height: 8px;
                                    .background-img-max(url('@{assetsUrlV2_0}/taskCenter/sign_num.png'));
                                }
                            }
                        }

                        &.c2_1 {
                            .background-img-max(url('@{assetsUrlV2_0}/taskCenter/sign.png'));
                        }

                        &.c2_2 {
                            .background-img-max(url('@{assetsUrlV2_0}/taskCenter/unSign.png'));
                        }

                        >.calendar-text {
                            font-size: 9px;
                            color: #fff;
                            font-weight: bold;
                            margin-bottom: 2px;
                        }
                    }
                }
            }

            >.center-content {
                position: absolute;
                width: 416px;
                height: 117px;
                left: 50%;
                bottom: 25px;
                transform: translateX(-50%);

                >.tab-menu {
                    position: absolute;
                    width: 60px;
                    height: 26px;
                    top: -19px;
                    cursor: pointer;
                    .background-img-max(url('@{assetsUrlV2_0}/taskCenter/special.png'));

                    >.tab-text {
                        position: absolute;
                        left: 50%;
                        top: 1px;
                        transform: translateX(-50%);
                        font-size: 10px;
                        color: #ffffff;
                        font-weight: bold;
                        white-space: nowrap;
                    }

                    &.normal {
                        left: 9px;
                        z-index: 1;
                    }

                    &.special {
                        left: 80px;
                        z-index: 1;
                    }

                    &.t-active {
                        top: -19.5px;
                        height: 22px;
                        .background-img-max(url('@{assetsUrlV2_0}/taskCenter/daily.png'));
                        z-index: 3;

                        >.tab-text {
                            color: #FF9B1C;
                        }
                    }
                }

                >.center-body {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    .background-img-max(url('@{assetsUrlV2_0}/taskCenter/bg_con.png'));

                    >.task-content {
                        position: relative;
                        width: 100%;
                        height: 100%;
                    }
                }

            }
        }
    }
}
</style>