<template>
    <div class="daily-task-center-index mini-scrollbar-task">
        <div class="daily-task-item" v-for="(v, i) in data" :key="i">
            <div class="item-left">
                <div class="left-img" :class="v.img"></div>
                <span class="left-text">{{ v.taskName }}</span>
            </div>
            <div class="item-center">
                {{ v.taskDetail }}
            </div>
            <div class="item-right flex-center" @click="goPlay(v)"
                :class="{ goPlay: v.taskStatus == 0, continue: v.taskStatus != 0 }">
                <span class="right-text">{{ v.taskStatus == 0 ? "去挑战" : "继续挑战" }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        data: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
        };
    },
    methods: {
        goPlay(v) {
            this.$emit('goPlay', v)
        }
    },
    created() {
    },
    components: {
    },
    computed: {
    },
    watch: {
    },
    mounted() {
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() {
    }, //生命周期 - 更新之前
    updated() {
    }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
.daily-task-center-index {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 5px 20px 0 20px;
    // padding: 0px 20px;

    >.daily-task-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #FAD897;
        padding: 4px 0;

        &:last-child {
            border-bottom: none;
        }

        >.item-left {
            flex-shrink: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            >.left-text {
                flex-shrink: 0;
                font-size: 10px;
                color: #8D6C53;
                font-weight: bold;
                white-space: nowrap;
            }

            >.left-img {
                flex-shrink: 0;
                width: 26px;
                height: 26px;
                margin-right: 9px
            }

            >.win {
                .background-img-max(url('@{assetsUrlV2_0}/taskCenter/win.png'));
            }

            >.work {
                .background-img-max(url('@{assetsUrlV2_0}/taskCenter/work.png'));
            }

            >.video {
                .background-img-max(url('@{assetsUrlV2_0}/taskCenter/video.png'));
            }
        }

        >.item-center {
            flex-shrink: 0;
            width: 158px;
            white-space: pre-wrap;
            word-break: break-all;
            color: #8B8B8B;
            text-align: center;
            font-size: 9px;
            font-weight: bold;
            line-height: 13px;
        }

        >.item-right {
            flex-shrink: 0;
            width: 60px;
            height: 26px;

            &.goPlay {
                .background-img-max(url('@{assetsUrlV2_0}/taskCenter/go.png'));
            }

            &.continue {
                .background-img-max(url('@{assetsUrlV2_0}/taskCenter/continue.png'));
            }

            >.right-text {
                font-size: 10px;
                color: #ffffff;
                font-weight: bold;
                margin-bottom: 4px;
            }
        }
    }

    &.mini-scrollbar-task {

        &::-webkit-scrollbar {
            width: 14px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: linear-gradient(to right, #f8cc4b, #fbe778, #fbe26b);
        }

        &::-webkit-scrollbar-thumb::before {
            content: '';
        }

        &::-webkit-scrollbar-track {
            border-radius: 4px;
            background-image: linear-gradient(to right, #f3b24c, #f9b954, #f7943b);
        }

        & {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}
</style>